html,
body {
   position: relative;
   /* height: 100%; */
}

body {
   background: #ffffff;
   font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
   font-size: 14px;
   color: #000;
   margin: 0;
   padding: 0;
}

.swiper {
   width: 100%;
   max-height: 100%;
}

.swiper-slide {
   text-align: center;
   font-size: 18px;
   background: rgb(255, 255, 255);
   /* background: linear-gradient(153deg, rgba(147,205,221,1) 0%, rgba(147,205,221,1) 44%, rgba(255,255,255,1) 100%); */
   /* Center slide text vertically */
   display: -webkit-box;
   display: -ms-flexbox;
   display: -webkit-flex;
   display: flex;
   height: auto;
   flex-direction: column;
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   -webkit-justify-content: center;
   justify-content: flex-start;
   -webkit-box-align: center;
   -ms-flex-align: center;
   -webkit-align-items: center;
   align-items: center;
   padding-left: 15px;
   padding-right: 20px;
   box-sizing: border-box;
}

.swiper-slide img {
   display: block;
   /* width: 100%;
   height: 100%; */
   object-fit: cover;
}

.swiper-v {
   background: #eee;
}

.hubTaskBox {
   background: #eee;
   display: block;
   min-height: min-content;
   padding: 10px;
   margin-top: 10px;
   margin-left: 8px;
   border-radius: 10px;
   border-left: 10px solid #215968;
   cursor: pointer;
}

.reconcil_doc_type {
   background: #eee;
   display: block;
   min-height: min-content;
   padding: 10px;
   margin-top: 10px;
   margin-left: 8px;
   border-radius: 10px;
   border-left: 10px solid #215968;
   cursor: pointer;
}

.execution_doc_type {
   background: #eee;
   display: block;
   min-height: min-content;
   padding: 10px;
   margin-top: 10px;
   margin-left: 8px;
   border-radius: 10px;
   border-left: 10px solid #004b88;
   cursor: pointer;
}

.reconciled_doc_type {
   background: #eee;
   display: block;
   min-height: min-content;
   padding: 10px;
   margin-top: 10px;
   margin-left: 8px;
   border-radius: 10px;
   border-left: 10px solid #004b88;
   cursor: pointer;
}

.returned_doc_type {
   background: #eee;
   display: block;
   min-height: min-content;
   padding: 10px;
   margin-top: 10px;
   margin-left: 8px;
   border-radius: 10px;
   border-left: 10px solid #00886a;
   cursor: pointer;
}

.consideration_doc_type {
   background: #eee;
   display: block;
   min-height: min-content;
   padding: 10px;
   margin-top: 10px;
   margin-left: 8px;
   border-radius: 10px;
   border-left: 10px solid #5f5602;
   cursor: pointer;
}

.hubTaskBox:hover {
   background: rgb(219, 219, 219);
}

.hubTaskBoxVisa {
   border-left: 10px solid #8aa8b1;
}

.hubTaskBoxRejected {
   border-left: 10px solid rgb(255, 148, 148);
}

.hubSubDescription {
   color: gray;
   text-align: left;
   font-size: 8pt;
   padding-top: 5px;
}

.hubDescription {
   text-align: left;
   color: black;
   font-size: 10pt;
}

.hubUnread {
   font-weight: bold;
}

.hubTimeBox {
   display: block;
   color: gray;
   width: 100%;
   height: 30px;
   font-size: small;
   padding-top: 7px;
   position: -webkit-sticky;
   position: sticky;
   top: 0px;
   z-index: 1;
   background: #fff;
}

.hubSearchBox {
   display: block;
   color: gray;
   width: 100vw;
   padding: 5px;
   height: 40px;
   text-align: left;
   background: rgb(255, 255, 255);
   top: 0px;
   position: sticky;
   z-index: 900;
}

.navigationPanel {
   width: 100%;
   background-color: #fff;
   padding-top: 5px;
   padding-bottom: 5px;
   top: 0px;
   text-align: center;
}

.hub-waiting {
   position: fixed;
   z-index: 1000;
   background-color: rgba(255, 255, 255, 0.5);
   width: 100%;
   height: 100%;
   justify-content: center;
   align-items: center;
   display: flex;
}

#taskItems::-webkit-scrollbar {
   display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.taskItems {
   -ms-overflow-style: none;
   /* IE and Edge */
   scrollbar-width: none;
   /* Firefox */
}

.unprocessedClass {
   font-weight: bold;
}

.warningText {
   font-weight: bold;
   color: #0000006e;
}

.loginPage {
   padding: 10px;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   background: rgb(0, 0, 0);
   min-height: 100vh;
   min-width: 100vw;
   background: rgb(0, 0, 0);
   background: linear-gradient(146deg, rgba(0, 0, 0, 1) 0%, rgba(203, 203, 203, 1) 17%, rgba(187, 187, 187, 1) 88%, rgba(0, 0, 0, 1) 100%);
}

.loginPanel {
   width: 80vw;
}

.hub-white {
   color: #fff;
}

.hub-w100 {
   width: 100%;
}

.hubDocDetails {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: row;
   background-color: #fff;
   margin-top: 8px;
   border-top-left-radius: 30px;
   border-top-right-radius: 30px;
   padding: 10px 5px 20px 5px;
}

.hub-swiper-slide {
   padding: 0;
}

.hub-main-text {
   color: #000;
   font-size: large;
}

.hubButton {
   color: rgb(65, 65, 65);
   padding: 5px;
   cursor: pointer;
   display: block;
   border-top: #eee;
   width: 100%;
   flex-shrink: 0;
   border-bottom-width: 1px;
   border-bottom-color: rgb(211, 211, 211);
   border-bottom-style: solid;
}

.hubOkButton {
   background-color: #ffffff;
   margin-top: 15px;
}

.hubCancelButton {
   background-color: #ffffff;
}

.hubOkButton:hover {
   background-color: rgb(211, 211, 211);
}

.hubCancelButton:hover {
   background-color: rgb(211, 211, 211);
}

.hubshortText {
   font-weight: bold;
   font-size: medium;
}

.hubdocKindName {
   font-style: italic;
   color: darkgrey;
   font-size: small;
}

.signPanel {
   display: contents;
}

.hubBackground {
   background-color: #eee;
}

.roundedImage {
   border-radius: 50%;
   height: 50px;
   width: 50px;
   position: relative;
   top: -15px;
   border-style: solid;
   border-width: 1px;
   border-color: #cfcdcd;
   cursor: pointer;
   z-index: 900;
}

.roundPhoto {
   border-radius: 50%;
   height: 70px;
   width: 70px;
   background-size: 70px;
   display: inline-block;
   /* background-image: url("http://map.ukrtransnafta.com/api/userphoto/r.anisimov@ukrtransnafta.com"); */
   border-style: solid;
   border-width: 1px;
   border-color: #cfcdcd;
   cursor: pointer;
   z-index: 900;
   margin-left: 5px;
   margin-right: 5px;
}

.attachImage {
   border-radius: 50%;
   height: 50px;
   width: 50px;
   border-style: solid;
   border-width: 1px;
   border-color: #cfcdcd;
   cursor: pointer;
}

.attachFile {
   border-bottom: #eee 1px solid;
   width: 100%;
}

.box-shadow:after {
   content: "";
   position: absolute;
   width: 100%;
   bottom: 1px;
   z-index: -1;
   transform: scale(.9);
   box-shadow: 0px 0px 8px 2px #000000;
}

.hubTaskPictogram {
   display: inline;
   width: 15px;
}

.green {
   color: rgb(0, 134, 90);
}

.hub-panel {
   display: contents;
}

.currentReconciliation {
   font-weight: bolder;
   font-size: small;
}

.reconciliationPosition {
   font-size: small;
}

.reconciliationCompleted {
   color: rgb(1, 110, 68);
}

.reconciliationDate {
   color: gray;
   display: block;
   margin-left: .5em;
}

.input-text {
   color: gray;
}

.comment-panel {
   font-size: small;
   background-color: #fff;
   width: 100%;
   border-color: #fff;
   padding: 7px;
}

.comment-panel-outer {
   border-color: rgb(131, 131, 131);
   border-radius: 5px;
   border-style: solid;
   border-width: 1px;
   padding: 5px;
   background-color: rgb(255, 255, 255);
   margin: 5px;
}

.hubTaskBar {
   display: flex;
   width: 100%;
   height: 40px;
   background-color: #ffffff;
   position: fixed;
   bottom: 0px;
   z-index: 100;
   justify-content: right;
   padding: 5px;
}

.hubTaskBarButton {
   display: block;
   width: 30px;
   height: 30px;
}

.hubLogoutButton {
   background-image: url("/public/images/logout.png");
   background-color: #fff;
   background-repeat: no-repeat;
   background-size: 100% auto;
   background-position: center center;
   cursor: pointer;
}

.hubItems {
   padding: 0px;
}

.swiper-slide.hubItems {
   background-color: #f2f2f2;
}

.hubPanel {
   background-color: #fff;
   width: 100%;
   /* height: 100px; */
   text-align: left;
   padding: 5px 5px 5px 0;
   display: flex;
}

.hubDDLeft {
   width: 15vw;
}

.hubDDRight {
   width: 80vw;
}

.hub-attach-panel {
   display: block;
   text-align: left;
   position: relative;
   top: -20px;
}

.hub-attach-elem {
   display: flex;
   background-color: #fff;
   /* padding: 0px 5px 0px 5px; */
   cursor: pointer;
   justify-content: left;
   padding: 7px;
}

.hub-attach-elem:hover {
   background-color: rgb(204, 204, 204);
}

.roundedImage img {
   display: inline;
}

.reconciliationRejected {
   color: rgb(122, 0, 0);
   border-color: rgb(122, 0, 0);
}

.hub-button {
   border-style: none;
   padding: 10px;
   background-color: #fff;
   min-width: 100%;
   cursor: pointer;
}

.hub-button:hover {
   background-color: rgb(199, 199, 199);
}

.hub-grey {
   color: gray;
}

.hub-width100 {
   width: 100vw;
   overflow-y: auto;
   top: -1px;
   background-color: rgb(255, 255, 255);
   height: calc(100vh - 89px);
}

.hub-search-panel {
   position: fixed;
   top: 0px;
   width: 100vw;
   background-color: #eee;
   z-index: 900;
   display: flex;
   flex-direction: column;
}

.hub-selected-text {
   background-color: #004b88;
   border-radius: 6px;
   padding: 3px;
   color: #fff;
}

.hub-filter-button {
   background-color: #eee;
   display: inline;
   margin-left: 5px;
   padding: 4px;
   border-radius: 6px;
}

.hub-workspace {
   flex-grow: 1;
   flex-shrink: 1;
   background-color: #ffffff;
   height: calc(100vh - 80px);
}

.hub-gray {
   background-color: #e7e7e7;
}

.hub-input {
   color: #fff;
}

.hub-bordered-panel {
   margin: 5px;
   padding: 8px;
   border: 1px solid rgb(148, 148, 148);
   background-color: #ffffff;
   border-radius: 4px;
}

.italic {
   font-style: italic;
}

.bold {
   font-weight: bold;
}

.margintop {
   margin-top: 15px;
}

.gray {
   color: rgb(24, 24, 24);
}

.resolutionPanel {
   display: flex;
   flex-direction: column;
   background-color: #fff;
   max-width: 100%;
   width: 100%;
   font-size: 12px;
   /* margin-top: 8px;
   margin-left: 10px;
   margin-right: 8px; */
   border-radius: 8px;
   border-color: green;
}

.left {
   text-align: left;
}

.flexRow {
   display: flex;
   flex-direction: row;
   align-items: flex-start;
}

.flexColumn {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
}

.marginLeft {
   margin-left: 5px;
}

textarea {
   width: 100%;
   height: 150px;
   padding: 12px 20px;
   box-sizing: border-box;
   border: 2px solid #ccc;
   border-radius: 4px;
   background-color: #f8f8f8;
   font-size: 16px;
   resize: none;
}

#password:autofill {
   background-color: red !important;
}

.searchresult {
   font-style: italic;
   text-align: justify;
}

.searchresult>searched {
   color: #000;
   background-color: #fafa30d3;
   font-weight: bold;
}
